@import "@core/scss/core.scss";
@import "./assets/scss/styles";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~quill/dist/quill.bubble.css";
@import "ngx-toastr/toastr";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.icon-size-sm {
  width: 20px !important;
  height: 20px !important;
  color: rgba(0, 0, 0, 0.54) !important;
  margin-right: 16px;
}

.ng-select-container {
  background: transparent !important;
  border: solid 1px #404757 !important;
}

.preview {
  height: 250px;
  border: 1px dashed lightgray;
  border-radius: 10px;
  text-align: center;
  label {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
.required-input {
  color: #ea5455;
}

.border-th,
.border-td {
  border: 1px solid #d8d6de;
}

.center {
  text-align: center;
}
